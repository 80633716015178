import { useUser } from '~~/stores/user';
import type { User } from '~~/stores/user';
import type { Client } from '@urql/vue';
import type { CookieRef } from 'nuxt/dist/app/composables/cookie';
import type { KeyCookie, CookieValue } from '../server/cookie.ts';
import type { CookieData } from './cookie';
import type { InstanceName } from './warehouse';

/**
 * Authorize a user based on [PKCE]{@Link https://datatracker.ietf.org/doc/html/rfc7636}
 * Fetch the user data based on the JWT stored in the cookie
 * and save his data into a user store
 */
export const setCurrentUser = async (cookie: CookieRef<CookieData>): Promise<void> => {

  const $apiClient = useNuxtApp().$apiClient as Client;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const getCookieData = (key: KeyCookie): CookieValue => {
    const cookieData = cookie.value as CookieData;
    return (cookieData) ? cookieData[key] : null;
  };

  const instanceName = (): InstanceName => getCookieData('instance_name') as InstanceName;
  /**
   * Get access token stored in the cookie
   * @returns {string|null} access token
   */
  const hasAccessToken = (): boolean => !!getCookieData(`access_token_${instanceName()}`);

  /**
   * Get the current user data
   * it fetches the user using the access token and store this current user data into the cookie
   * @returns {User|null} current user data
   */
  let userData = getCookieData(`current_user_${instanceName()}`) as Partial<User>;

  if (!userData && hasAccessToken()) {
    const result = await $apiClient.query(
      `query {
        viewer {
          id
          language
          email
          fullName
          nature
          isRoot
          isAdmin
          organization {
            id
            broadcasterId
            name
            identifier
            nature
            currency
            countryReferencePrice
            sellForLoans
          }
        }
      }`, {})
      .toPromise();
    useGraphqlResult(result, {
      skipSentry: true,
      onSuccess: (data) => {
        userData = data.viewer as User;
        updateCookieUser(userData);
      },
    });
  }

  if (userData) {
    const user = useUser();
    user.update({ ...userData } as User);
  }
};

export const currentUser = (): ReturnType<typeof useUser> => useUser();
