import { CombinedError } from '@urql/core';
import { useError } from '~~/stores/error';
import type { OperationResult } from '@urql/core';
import type { VueI18n } from 'vue-i18n';

/* eslint-disable  @typescript-eslint/no-explicit-any */
// TODO: need to fix the data type. Once we use codegen, we can use the generated types for the data

/**
 * Options for configuring the behavior of the useGraphqlResult function.
 */
interface UseGraphqlResultOptions {
  /**
   * If true, skips sending errors to Sentry.
   * @default false
   */
  skipSentry?: boolean;

  /**
   * Custom error message to display when an error occurs.
   * If not provided, a default error message will be used.
   */
  customErrorMessage?: string;

  /**
   * If true, skips checking for null fields at the first level in the result data.
   * If null fields are found at the first level, no error will be thrown.
   * @default false
   */
  skipNullCheck?: boolean;

  /**
   * If true, treats application-specific errors as regular errors and manages them accordingly.
   * @default true
   */
  manageAppErrors?: boolean;

  /**
   * If false, hides the error message from the error store.
   * @default true
   */
  displayError?: boolean;

  /**
   * Callback function to handle errors.
   * @param error - The CombinedError object containing error details.
   */
  onError?: (error: CombinedError) => void;

  /**
   * Callback function to handle successful data retrieval.
   * @param data - The data retrieved from the GraphQL query.
   */
  onSuccess?: (data: Record<string, any>) => void;
}

interface ApplicationError {
  message: string;
  code: string;
}

export const useGraphqlResult = (result: OperationResult, {
  skipSentry = false,
  customErrorMessage = '',
  skipNullCheck = false,
  manageAppErrors = true,
  displayError = true,
  onError,
  onSuccess,
}: UseGraphqlResultOptions): void => {
  const nuxtApp = useNuxtApp();
  const i18n = nuxtApp.$i18n as VueI18n;
  const DEFAULT_ERROR_MESSAGE = i18n.t('error.default');
  const errorStore = useError();
  const { $sentryCaptureException } = useNuxtApp();

  // Helper methods

  const sendSentry = (result: OperationResult, operationError: CombinedError): void => {
    const querySource = result.operation.query?.loc?.source?.body || 'N/A';
    const queryVariables = result.operation.variables || {};
    const errorMessage = operationError.message || 'N/A';

    const errorInstance = new Error(`GraphQL Error: ${errorMessage}`);
    $sentryCaptureException(errorInstance, {
      extra: {
        query: querySource,
        variables: queryVariables,
      },
    });
  };

  const manageError = (operationError: CombinedError): void => {
    if (onError) onError(operationError);
    if (!skipSentry) { sendSentry(result, operationError); }
    if (displayError) errorStore.update(customErrorMessage || DEFAULT_ERROR_MESSAGE);
  };

  const checkApplicationErrors = (): void => {
    const resultData = result.data;
    for (const key of Object.keys(resultData)) {
      const errors = resultData[key]?.errors;
      if (errors?.length > 0) {
        const applicationErrors = errors.map((err: ApplicationError) => ({
          message: err.message,
          extensions: { code: err.code },
        }));

        const combinedError = new CombinedError({
          graphQLErrors: applicationErrors,
        });

        manageError(combinedError);
        return;
      }
    }
  };

  const checkNullFields = (): void => {
    const resultData = result.data;
    const nullFields = Object.keys(resultData).filter((key) => resultData[key] === null);
    if (nullFields.length > 0) {
      const combinedError = new CombinedError({
        graphQLErrors: [
          { message: `Null fields: ${nullFields.join(', ')}` },
        ],
      });
      manageError(combinedError);
    }
  };

  // Main logic
  if (result.error) {
    // If the error is a 401, return without managing it,
    // as it will be handled by the authExchange in the GraphQL client
    if (result.error?.response?.status === 401) return;

    manageError(result.error);
  } else if (result.data) {
    if (manageAppErrors) checkApplicationErrors();
    if (!skipNullCheck) checkNullFields();
    if (onSuccess) {
      errorStore.reset();
      onSuccess(result.data);
    }
  }
};
