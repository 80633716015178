import { default as _401l2uIbdYP19Meta } from "/opt/buildhome/repo/pages/401.vue?macro=true";
import { default as _4047Xi4OILrTdMeta } from "/opt/buildhome/repo/pages/404.vue?macro=true";
import { default as _500ZgVhh9gs5lMeta } from "/opt/buildhome/repo/pages/500.vue?macro=true";
import { default as indexWiy4wlnDxvMeta } from "/opt/buildhome/repo/pages/collections/index.vue?macro=true";
import { default as collections67nvDxiH0zMeta } from "/opt/buildhome/repo/pages/collections.vue?macro=true";
import { default as indexA1sYisaHgRMeta } from "/opt/buildhome/repo/pages/contributors/index.vue?macro=true";
import { default as contributorsYROTh9i6McMeta } from "/opt/buildhome/repo/pages/contributors.vue?macro=true";
import { default as error5bJL3YgCAEMeta } from "/opt/buildhome/repo/pages/error.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as activitiesQRBJmeQrZZMeta } from "/opt/buildhome/repo/pages/organizations/[id]/activities.vue?macro=true";
import { default as index9gaU5USwTVMeta } from "/opt/buildhome/repo/pages/organizations/[id]/index.vue?macro=true";
import { default as _91_91step_93_93M8OaWH38JfMeta } from "/opt/buildhome/repo/pages/publication-drafts/[id]/[[step]].vue?macro=true";
import { default as indexl7xVAEJwVKMeta } from "/opt/buildhome/repo/pages/publication-drafts/index.vue?macro=true";
import { default as newBCpPm9vGqsMeta } from "/opt/buildhome/repo/pages/publication-drafts/new.vue?macro=true";
import { default as accessibilityg0yMCNP2gZMeta } from "/opt/buildhome/repo/pages/publications/[id]/accessibility.vue?macro=true";
import { default as categories0fKa5KsSboMeta } from "/opt/buildhome/repo/pages/publications/[id]/categories.vue?macro=true";
import { default as contributorsdcI3sfM7uNMeta } from "/opt/buildhome/repo/pages/publications/[id]/contributors.vue?macro=true";
import { default as formats8n9GQOldXwMeta } from "/opt/buildhome/repo/pages/publications/[id]/formats.vue?macro=true";
import { default as indexN7hqAlxdPaMeta } from "/opt/buildhome/repo/pages/publications/[id]/index.vue?macro=true";
import { default as library_45offersqzGRtfuqkSMeta } from "/opt/buildhome/repo/pages/publications/[id]/library-offers.vue?macro=true";
import { default as mainECCpzpK5WkMeta } from "/opt/buildhome/repo/pages/publications/[id]/main.vue?macro=true";
import { default as pricesDuXLkf6bSDMeta } from "/opt/buildhome/repo/pages/publications/[id]/prices.vue?macro=true";
import { default as related_45contents3O53Eyz648Meta } from "/opt/buildhome/repo/pages/publications/[id]/related-contents.vue?macro=true";
import { default as sales_45rights1EbwwRvFQ0Meta } from "/opt/buildhome/repo/pages/publications/[id]/sales-rights.vue?macro=true";
import { default as indexSUwaqOBqZdMeta } from "/opt/buildhome/repo/pages/publications/index.vue?macro=true";
import { default as publicationsOf6b0iFshDMeta } from "/opt/buildhome/repo/pages/publications.vue?macro=true";
import { default as securityuuQCcpTopPMeta } from "/opt/buildhome/repo/pages/user/security.vue?macro=true";
import { default as settings58UOXn7mWiMeta } from "/opt/buildhome/repo/pages/user/settings.vue?macro=true";
export default [
  {
    name: "401",
    path: "/401",
    meta: _401l2uIbdYP19Meta || {},
    component: () => import("/opt/buildhome/repo/pages/401.vue")
  },
  {
    name: "404",
    path: "/404",
    meta: _4047Xi4OILrTdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/404.vue")
  },
  {
    name: "500",
    path: "/500",
    meta: _500ZgVhh9gs5lMeta || {},
    component: () => import("/opt/buildhome/repo/pages/500.vue")
  },
  {
    name: collections67nvDxiH0zMeta?.name,
    path: "/collections",
    component: () => import("/opt/buildhome/repo/pages/collections.vue"),
    children: [
  {
    name: "collections",
    path: "",
    meta: indexWiy4wlnDxvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/collections/index.vue")
  }
]
  },
  {
    name: contributorsYROTh9i6McMeta?.name,
    path: "/contributors",
    component: () => import("/opt/buildhome/repo/pages/contributors.vue"),
    children: [
  {
    name: "contributors",
    path: "",
    meta: indexA1sYisaHgRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/contributors/index.vue")
  }
]
  },
  {
    name: "error",
    path: "/error",
    meta: error5bJL3YgCAEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "organizations-id-activities",
    path: "/organizations/:id()/activities",
    meta: activitiesQRBJmeQrZZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/organizations/[id]/activities.vue")
  },
  {
    name: "organizations-id",
    path: "/organizations/:id()",
    meta: index9gaU5USwTVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/organizations/[id]/index.vue")
  },
  {
    name: "publication-drafts-id-step",
    path: "/publication-drafts/:id()/:step?",
    meta: _91_91step_93_93M8OaWH38JfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publication-drafts/[id]/[[step]].vue")
  },
  {
    name: "publication-drafts",
    path: "/publication-drafts",
    meta: indexl7xVAEJwVKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publication-drafts/index.vue")
  },
  {
    name: "publication-drafts-new",
    path: "/publication-drafts/new",
    meta: newBCpPm9vGqsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publication-drafts/new.vue")
  },
  {
    name: publicationsOf6b0iFshDMeta?.name,
    path: "/publications",
    component: () => import("/opt/buildhome/repo/pages/publications.vue"),
    children: [
  {
    name: "publications-id-accessibility",
    path: ":id()/accessibility",
    meta: accessibilityg0yMCNP2gZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/accessibility.vue")
  },
  {
    name: "publications-id-categories",
    path: ":id()/categories",
    meta: categories0fKa5KsSboMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/categories.vue")
  },
  {
    name: "publications-id-contributors",
    path: ":id()/contributors",
    meta: contributorsdcI3sfM7uNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/contributors.vue")
  },
  {
    name: "publications-id-formats",
    path: ":id()/formats",
    meta: formats8n9GQOldXwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/formats.vue")
  },
  {
    name: "publications-id",
    path: ":id()",
    meta: indexN7hqAlxdPaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/index.vue")
  },
  {
    name: "publications-id-library-offers",
    path: ":id()/library-offers",
    meta: library_45offersqzGRtfuqkSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/library-offers.vue")
  },
  {
    name: "publications-id-main",
    path: ":id()/main",
    meta: mainECCpzpK5WkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/main.vue")
  },
  {
    name: "publications-id-prices",
    path: ":id()/prices",
    meta: pricesDuXLkf6bSDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/prices.vue")
  },
  {
    name: "publications-id-related-contents",
    path: ":id()/related-contents",
    meta: related_45contents3O53Eyz648Meta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/related-contents.vue")
  },
  {
    name: "publications-id-sales-rights",
    path: ":id()/sales-rights",
    meta: sales_45rights1EbwwRvFQ0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/sales-rights.vue")
  },
  {
    name: "publications",
    path: "",
    meta: indexSUwaqOBqZdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/publications/index.vue")
  }
]
  },
  {
    name: "user-security",
    path: "/user/security",
    meta: securityuuQCcpTopPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/user/security.vue")
  },
  {
    name: "user-settings",
    path: "/user/settings",
    meta: settings58UOXn7mWiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/user/settings.vue")
  }
]