import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return;

  const runtimeConfig: NuxtRuntimeConfig = useRuntimeConfig();
  const publicPages: Array<string> = runtimeConfig.public.public_pages;

  if (publicPages.includes(to.path)) return;

  const cookie = useHCookie();
  await setCurrentUser(cookie);
});
