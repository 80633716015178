import { LDPlugin, ldInit, useLDFlag } from 'launchdarkly-vue-client-sdk';
import type { Nature as UserRole } from '~/stores/user';

export type FeatureFlagInitClient = () => Promise<void>;
export type FeatureFlagInitFlags = void;
export type CanManageDraft = () => Promise<void>;
export type canManageOrganization = () => Promise<void>;

export default defineNuxtPlugin((nuxt) => {
  interface UserContext {
    kind: 'user';
    key: string;
    email: string;
    name: string;
    role: UserRole;
    organisation_identifier: string | null;
  }
  type FlagValue = boolean | string | number;
  type FlagKey = 'can_manage_draft' | 'can_manage_organization';
  interface Flag {
    can_manage_draft?: FlagValue;
    can_manage_organization?: FlagValue;
  }

  const { vueApp } = nuxt;
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig.public;

  vueApp.use(LDPlugin, {
    clientSideID: config.launchDarkly.clientSideID,
    deferInitialization: true,
  });

  const flags: Flag = {
    can_manage_draft: undefined,
    can_manage_organization: undefined,
  };

  /**
   * Initialize LaunchDarkly client based on the current user context
   * @returns {ldClient} LaunchDarkly client promise
   */
  const initClient = async (): Promise<void> => {
    if (config.env === 'development') return Promise.resolve();

    const user = currentUser();
    const userData = {
      kind: 'user',
      key: `${instanceName()}${user.id}_user`, // 'enqc77_user',
      name: user.fullName, // 'Firstname Lastname'
      email: user.email, // email@demarque.com
      role: user.nature.toLowerCase(), // 'root'
      organisation_identifier: user.organization ? `${getInstanceName()}${user.organization?.id}` : null, // enqc25
    } as UserContext;

    const ldClient = ldInit({ user: userData })[1];
    return ldClient.waitUntilReady();
  };

  /**
   * Fetch the feature flags from LaunchDarkly and store them in the flags object
   * @returns {void}
   */
  const initFlags = (): void => {
    Object.keys(flags).forEach((key) => setFlag(key as FlagKey, false, true));
  };

  /**
   * Get Feature flag by key name from LaunchDarkly
   * Will memoize the call to avoid fetching the same flag multiple times
   * In development mode, return the default local value passed in parameter
   * Otherwise, use the LaunchDarkly value or fallback on the default value passed in parameter
   * @param {string} key that matches the feature flag key in LaunchDarkly
   * @param {boolean|string|number} defaultValue default value to return if the feature flag is not found in LaunchDarkly
   * @param {boolean|string|number} defaultDevelopmentValue default value to return in development mode
   * @returns {boolean|string|number} feature flag value
   */
  const setFlag = (key: FlagKey, defaultValue: FlagValue, defaultDevelopmentValue?: FlagValue): FlagValue => {
    if (flags[key] !== undefined) {
      // fast return if the flag has already been fetched as there is no need to fetch it again
    } else if (config.env === 'development') {
      flags[key] = defaultDevelopmentValue ?? true;
    } else if (flags[key] === undefined) {
      flags[key] = useLDFlag(key, defaultValue).value;
    }

    return flags[key] as FlagValue;
  };

  return {
    provide: {
      featureFlagInitClient: initClient,
      featureFlagInitFlags: initFlags,
      canManageDraft: (): FlagValue => flags.can_manage_draft as FlagValue,
      canManageOrganization: (): FlagValue => flags.can_manage_organization as FlagValue,
    },
  };
});
